/*
    main.js -- Manager main
 */

//  Import the manager class, Vuetify helper and Manager CSS
import {createManager, vuetifyProps} from 'manager'
import 'manager/manager.css'
import 'manager/extras.css'

//  Import the GraphWidget and AWS Cognito authenticator from the managers extras
import {Cognito, GraphWidget} from 'manager/extras'

//  Import core Vue, VueRouter and Vuetity components
import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

//  Import App configuration 
import Schema from '../../../../config/schema.json5'
import Build from '../../config/build.json5'
import Ioto from '../../config/ioto.json5'

/*
    Can decide at runtime which display to render
 */
import Display from '../../config/display.json5'
// import MobileDisplay from '../../config/mobile-display.json5'

//  Backend configuration provided by the cloud service
import Connect from '../Connect.js'

//  Import App VueJS components
import Main from './Main.vue'
import Custom from './Custom.vue'
import About from './About.vue'

//  Import additional MaterialDesign icons
import {mdiFormatListBulletedSquare} from '@mdi/js'
const Icons = {
    events: mdiFormatListBulletedSquare,
}

async function main() {
    /*
        Construct VueJS, Vue Router and Vuetify UI
     */
    let vue = createApp(Main)
    let router = createRouter({
        history: createWebHashHistory(),
        routes: [],
    })
    let display = Display
    /*
        Runtime display selection. Ability to have a different UI for different devices.

        if (window.innerWidth <= 640) {
            display = MobileDisplay
        }
     */
    let vuetify = createVuetify(
        vuetifyProps({
            components: components,
            directives: directives,
            icons: Icons,
            themes: display.theme.themes,
        })
    )
    vue.use(vuetify)
    let profile = process.env.PROFILE || Ioto.profile
    let connect = Connect[profile] || Connect
    let logo = Connect.logo || display.theme.logo

    await createManager({
        components: { About, Custom },              //  App VueJS components
        schema: Schema,                             //  Database schema 
        widgets: {graph: GraphWidget},              //  Dashboard widgets
        cognito: Cognito,                           //  AWS Cognito authenticator
        display: display,                           //  UI Display definition
        router,                                     //  VueJS router
        vue,                                        //  Vue instance
        vuetify,                                    //  Vuetify instance
    }, {
        api: connect.api,                           //  Backend cloud service API
        cognito: connect.cognito,                   //  Cognito config
        build: Build.build,                         //  App build number
        host: connect.host,                         //  Optional override hostname
        logo: logo,                                 //  App logo
        profile: profile,                           //  Vite execution profile (prod | dev)
        name: display.name,                         //  App name
        title: connect.title || display.title,      //  App display title
        version: display.version,                   //  App version (x.y.z)
    })

    /*
        Start VueJS and authenticate the user
     */
    vue.use(router)
    vue.mount('#main')
    router.push({path: '/auth/login'})
}

main()
